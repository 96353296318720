<template>
  <div>
    <my-nav-bar
      title="签单明细"
      left-text="返回"
      left-arrow
    />
    <div class="list-container app-main-container">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getOrders"
      >
        <div
          v-for="(order, index) in orders"
          :key="index"
          class="content-container"
        >
          <van-cell
            class="unpaid-order-head-cell"
            title="客户名称"
            :value="order.client.name"
          />
          <van-cell class="unpaid-order-head-cell" :title="order.type === 1 ? '订单编号' : '退货单号'" :value="order.num" />
          <van-cell v-if="order.unpaid_amount" class="unpaid-order-head-cell" title="签单金额">
            <span class="unpaid-order-head-cell__amount">￥{{ order.unpaid_amount }}</span>
          </van-cell>
          <van-cell v-if="order.unpaid_return_amount < 0" border class="unpaid-order-head-cell" title="签单退货金额">
            <span class="unpaid-order-head-cell__amount">￥{{ order.unpaid_return_amount }}</span>
          </van-cell>
          <van-cell title="修改支付方式" is-link @click="handleShowActionSheet(order)" />
        </div>
      </van-list>
    </div>

    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="onActionSheetSelect" />
  </div>
</template>

<script>
import billCheckApi, { modifyPayType } from '@/api/bill_check'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'UnpaidOrder',
  components: { myNavBar },
  data() {
    return {
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      orders: [],
      showActionSheet: false,
      actions: [
        { name: '线上', value: 1 },
        { name: '现金', value: 2 },
        { name: '转账', value: 3 }
      ],
      currentOrder: null
    }
  },
  methods: {
    getOrders() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        bill_id: this.$route.query.bill_id
      }
      billCheckApi.unpaidOrder(params).then(res => {
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
        this.orders = this.orders.concat(res.data.list)
      })
    },
    getOrderNums(orders) {
      const nums = orders.map(order => order.num)
      return nums.join(',')
    },
    handleShowActionSheet(order) {
      this.showActionSheet = true
      this.currentOrder = order
    },
    reload() {
      this.page = 0
      this.finished = false
      this.orders = []
      this.getOrders()
    },
    onActionSheetSelect(action) {
      this.showActionSheet = false
      this.confirm('确定要修改签单支付方式为【' + action.name + '】吗？').then(() => {
        const data = {
          is_unpaid: 1,
          order_type: this.currentOrder.type,
          pay_type: action.value,
          order_id: this.currentOrder.id
        }
        this.beginLoad()
        modifyPayType(data).then(res => {
          this.endLoad()
          this.success(res.msg)
          this.reload()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-container {
    padding: 15px;
  }
  .unpaid-order-head-cell {
    // padding: 5px 15px;
    &__amount {
      color: #ee0a24;
    }
  }
  .order-num-container {
    width: 343px;
    overflow: hidden;
    word-break: break-word;
  }
  .content-container {
    margin-bottom: 10px;
    // padding: 5px;
    background-color: #fff;
    .van-cell__value {
      flex: 2.5;
    }
  }
</style>
