var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "签单明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "list-container app-main-container"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getOrders
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('div', {
      key: index,
      staticClass: "content-container"
    }, [_c('van-cell', {
      staticClass: "unpaid-order-head-cell",
      attrs: {
        "title": "客户名称",
        "value": order.client.name
      }
    }), _c('van-cell', {
      staticClass: "unpaid-order-head-cell",
      attrs: {
        "title": order.type === 1 ? '订单编号' : '退货单号',
        "value": order.num
      }
    }), order.unpaid_amount ? _c('van-cell', {
      staticClass: "unpaid-order-head-cell",
      attrs: {
        "title": "签单金额"
      }
    }, [_c('span', {
      staticClass: "unpaid-order-head-cell__amount"
    }, [_vm._v("￥" + _vm._s(order.unpaid_amount))])]) : _vm._e(), order.unpaid_return_amount < 0 ? _c('van-cell', {
      staticClass: "unpaid-order-head-cell",
      attrs: {
        "border": "",
        "title": "签单退货金额"
      }
    }, [_c('span', {
      staticClass: "unpaid-order-head-cell__amount"
    }, [_vm._v("￥" + _vm._s(order.unpaid_return_amount))])]) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "修改支付方式",
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleShowActionSheet(order);
        }
      }
    })], 1);
  }), 0)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.onActionSheetSelect
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }